<template>
	<page-container :page="page">
		<heading-image
			v-if="page && page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>
		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:booking-id="page.bookingID"
		>
			<template #bgImage>
				<picture>
					<source srcset="~/assets/images/breathe-hotel-bg.webp" type="image/webp" />
					<source srcset="~/assets/images/breathe-hotel-bg.jpg" />
					<img
						class="fade-image"
						src="~/assets/images/breathe-hotel-bg.webp"
						alt="Booking benefits"
						loading="lazy"
					/>
				</picture>
			</template>
		</main-content>

		<section class="main-content benefits">
			<div class="row">
				<div class="columns column4">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionBenefitsSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.sectionBenefitsTitle }}
					</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].homepage.bookingBenefits" :key="key">
							<span>{{ benefit }}</span>
						</li>
					</ul>
					<book-button class="button button-cta reserve-now" aria-label="Reserve now">
						<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
						<span>{{ $t('bookNow') }}</span>
					</book-button>
				</div>
			</div>
			<picture>
				<source
					v-if="defaults[locale].homepage.sectionBenefitsImageWebp"
					:srcset="defaults[locale].homepage.sectionBenefitsImageWebp"
					type="image/webp"
				/>
				<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
				<img
					class="fade-image"
					:src="defaults[locale].homepage.sectionBenefitsImage"
					:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<promoblocks
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="6"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:items="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<rooms-overview
			:items="roomsData"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
		/>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
		<news-overview :items="news">
			<template #section-intro>
				<div class="row row-header align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionNewsTitle }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionNewsContent" />
				</div>
			</template>
		</news-overview>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});
</script>

<style lang="scss" scoped>
.benefits .fade-image {
	opacity: 1;
}

.benefits {
	position: relative;
	padding: 75px 0;
}

.benefits .row {
	z-index: 2;
	position: relative;
}

.benefits .column4 {
	background: rgba(249 246 242 / 80%);
	box-shadow: 0 18px 18px rgba(0 0 0 / 25%);
	backdrop-filter: blur(34px);
	padding: 60px 50px;
	width: 40%;
}

.benefits ul {
	list-style: none;
	font-size: 20px;
	margin: 30px 0;
}

.benefits li {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	margin: 20px 0;

	span {
		width: calc(100% - 70px);
	}
}

.benefits li::before {
	content: '';
	background: url('~/assets/images/benefits-key-img.png') no-repeat center center;
	background-size: 50px;
	height: 50px;
	width: 50px;
	display: block;
	margin: 0 25px 0 0;
}

@media (max-width: 1180px) {
	.benefits .column4 {
		width: 50%;
	}
}

@media (max-width: 920px) {
	.benefits .column4 {
		width: 100%;
	}
}
</style>
